/* user.css */

/* Global styles */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

/* App container */
.app-container {
  font-family: 'Mon', sans-serif;

  display: flex;
  height: 100vh;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  padding: 20px;
  background-color: #013148;
  color: #ecf0f1;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



.user-name {
  font-size: 18px;
  font-weight: bold;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  padding: 10px;
  border-bottom: 1px solid #34495e;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #1f2c38;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #013148;
  list-style: none;
  padding: 10px;
  margin: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 8px 0;
  cursor: pointer;
}

/* MainContent styles */
.main-content {
  position: relative;
  flex: 1;
  padding: 50px;
  background-color: #ecf0f1;
  color: #2c3e50;
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds the height */
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.top-right-icons {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
}
.profile-pic1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-button {
  background-color: transparent;
  border: none;
  color: #3498db;
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 60px;
  font-size: 32px;

}
.applications-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.applications-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.application {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.application:hover {
  transform: scale(1.05);
}

.application-type {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.application-info {
  font-size: 14px;
  color: #666;
}

.info-label {
  font-weight: bold;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.chart-container {
  width: 45%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.notification-badge {
  position: absolute;
  top: -3px;
  right: 7px;
  background-color: #e74c3c;
  color: #fff;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 10px;
}

.notification-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card-row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}
/* .notification-button:hover {
  border:1px solid #013148;
  border-radius: 50%;
} */

.bell-icon {
  font-size: 20px;
  margin-right: 5px; /* Adjust margin as needed */
}

/* .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
} */

.full-width-card {
  flex: 1 0 100%; /* Allow the ApplicationsCard to take the full width */
}

.card {
  flex-basis: calc(50% - 20px); /* Adjust to make 2 cards per row with some gap */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px; /* Make the cards rounded */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.02);
}

.card h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  color: #666;
}

/* UserProfile styles */
.user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  position: absolute;
  top: 20px; /* Adjust top position as needed */
  right: 50px; /* Adjust right position as needed */
  margin-bottom: 20px;

}

.user-details h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.user-details p {
  font-size: 16px;
  color: #333;
}

/* Additional styles for other components if needed */
