.fade-up-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out; /* Adjust the duration as needed */
}

.fade-up-in.animated-text {
  opacity: 0;
  transform: translateY(20px);
}
.image-container {
  margin-bottom:  14px;
  max-width: 200%; /* Ensures the image won't exceed its natural size */
  overflow: hidden; /* Hides any overflow beyond the container */
}