/* Add your preferred font and styling here */
.four-box-container {
    display: flex;
    height: 100vh;
  }
  .four-box-sidebar {
    border: 1px solid #f1f0f0;
      background-color: white; /* Set the background color of the sidebar to white */
      padding: 40px; /* Add padding to the sidebar */
      text-align: center; /* Center-align the content within the sidebar */
      max-width: 340px; /* Set the maximum width of the sidebar */
      position: relative; /* Set position to relative for absolute positioning of the logo */
    }
    .logo {
      height: auto; /* Maintain the aspect ratio */
      margin-bottom: 10px; /* Add spacing between logo and contact info */
      position: absolute; /* Position the logo absolutely within the sidebar */

    }
    .contact-info {
      color: #929191;
      text-align: left;
      }
      .sidebar-text{
        text-align: justify;
        color: #b0afaf;
      }
  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    /* Your laptop-specific styles here */
    .four-box-sidebar {
        max-width: 350px; /* Set the maximum width of the sidebar */
      }
      .logo {
        width: 250px; /* Set the width of the logo */
        top: 50px; /* Adjust the top position of the logo */
        left: 100%; /* Center the logo horizontally */
        transform: translateX(-100%); /* Center the logo horizontally */
      }
      .contact-info {
        font-size: 10px;
        line-height:1.2em;
          margin-top: 300px; /* Add spacing between logo and contact info */
        }
        .sidebar-text{
          width: 250px;
          margin-top: 20px;
          font-size: 10px;
        }
        .img-price1 {
          width: 350px; /* Set the width of the logo */
          margin-bottom: 10px; /* Add spacing between logo and contact info */
          bottom: -150px; /* Adjust the top position of the logo */
          transform: scale(1.5);
          z-index: -1;
          }
          .modal-overlay {
            top: 10%;
            left: 20%;
            width: 70%;
            height: 80%;
          }
    }
    
    /* Styles for desktops (assuming screens wider than 1366px) */
    @media only screen and (min-width: 1367px) {
      .four-box-sidebar {
          max-width: 340px; /* Set the maximum width of the sidebar */
        }
        .logo {
          width: 350px; /* Set the width of the logo */
          top: 120px; /* Adjust the top position of the logo */
          left: 100%; /* Center the logo horizontally */
          transform: translateX(-100%); /* Center the logo horizontally */
        }
        .contact-info {
          font-size: smaller;
            margin-top: 540px; /* Add spacing between logo and contact info */
          }
          .sidebar-text{
            width: 250px;
            margin-top: 50px;
            font-size: 12px;
          }
          .img-price1 {
            width: 350px; /* Set the width of the logo */
            margin-bottom: 10px; /* Add spacing between logo and contact info */
            bottom: -220px; /* Adjust the top position of the logo */
            transform: scale(2);
          z-index: -1;

            }
            .modal-overlay {
              top: 20%;
              left: 30%;
              width: 50%;
              height: 60%;
            }
    }
    @media only screen and (max-width: 600px) {
      .four-box-sidebar {
          display: none; /* Set the maximum width of the sidebar */
        }
        .logo {
          display: none; /* Center the logo horizontally */
        }
        .contact-info {
          display: none; 
          }
          .sidebar-text{
            display: none; 
          }
          .img-price1 {
            display: none; 

            }
            .modal-overlay {
              top: 20%;
              left: 30%;
              width: 50%;
              height: 60%;
            }
    }
    .modal-overlay {
      position: fixed;
      border: 0.5px solid rgb(224, 223, 223);
      box-shadow: 0px 5px 20px #9d9b9b;
      border-radius: 30px;
      background: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000; /* Make sure it's higher than other elements on the page */
    }
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:0px;
    }
    .img-price1 {
      position: absolute;
      height: auto; /* Maintain the aspect ratio */
      margin-bottom: 10px; /* Add spacing between logo and contact info */
      left: 0%; /* Center the logo horizontally */
      }
  .logo1 {
    width: 150px; /* Set the width of the logo */
    height: auto; /* Maintain the aspect ratio */
    margin-bottom: 0px; /* Add spacing between logo and contact info */
    position: absolute; /* Position the logo absolutely within the sidebar */
    top: -20px; /* Adjust the top position of the logo */
    left: 100%; /* Center the logo horizontally */
    transform: translateX(-170%); /* Center the logo horizontally */
    
  }
 
  .four-box-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }

  .img-price {
  position: absolute;
  width: 150px; /* Set the width of the logo */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: 10px; /* Add spacing between logo and contact info */
  position: absolute; /* Position the logo absolutely within the sidebar */
  top: 0px; /* Adjust the top position of the logo */
  left: 120%; /* Center the logo horizontally */
  transform: translateX(-100%); /* Center the logo horizontally */
  }

    .combined-input-container {
      position: relative;
      display: flex;
      align-items: center;
      /* gap: 10px; */
    }
    
    .amount-input-container {
      position: relative;
    }
    
    .amount-input {
      border: 1px solid #ccc;
      border-radius: 8px;
      outline: none;
      padding-left: 50px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      width: 250%;
    }
    
    .prefix-dollar {
      position: absolute;

      left: 20px;
      top: 50%;
      /* padding-right: 50px; */
      transform: translateY(-50%);
    }
    .prefix-percentage {
      position: absolute;
font-weight: bolder;
transform: scale(2);
      left: 170px;
      top: 15px;
    }
    .select-input {
      position: absolute;
      left: 480px;
      top: 10px;
      border: 0px solid #ccc;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      width: 200px; /* Adjust the width based on your preference */
    }
    .amount-input1-container {
      position: relative;
    }
    
    .amount-input1 {
      border: 1px solid #ccc;
      border-radius: 8px;
      outline: none;
      padding-left: 10px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      width: 150%;
    }
    
    .select-input1 {
      position: absolute;
      left: 220px;
      top: 15px;
      border: 0px solid #ccc;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      width: 120px; /* Adjust the width based on your preference */
    }
    
  .four-box-content h5{
    text-align: left;
    font-size: 26px;
    font-weight: 340;
  }
  .four-box-radio-group {
    text-align: center;
    display: flex;
    margin-top: 10px; /* Add spacing between radio boxes */
  }

 /* Modal.css */
 .imgprice {
  width: 150px; 
  height: auto; 
  margin: 20px; 
  }
 .custom-modal {
  width: 700px; /* Adjust the width as needed */
  margin: auto;
}


.custom-overlay {
  /* background: rgba(0, 0, 0, 0.5); */
}


.close-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.close-button {
position: absolute;
top: 10px;
right: 10px;
font-size: 20px;
cursor: pointer;
background: none;
border: none;
}

.modal-content {
margin-top: 20px;
}

  .four-box {
    margin-right: 20px; /* Adjust spacing between radio boxes */
    cursor: pointer;
    border: 1px solid #ccc; /* Add a 1px gray border */
    border-radius: 8px;
    padding: 10px;
    transition: color 0.3s ease; 
  }
  
  .four-box:hover {
    border-color: #00a8a8; /* Change border color on hover */
    color: #00a8a8;
    transition: color 0.3s ease; 
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  }
  .radio-box:hover {
    border-color: #00a8a8; /* Change border color on hover */
  }
  .icon {
    /* Set default styles for the icon */
    color: #9d9b9b; /* Default color */
    transition: color 0.3s ease; /* Add a smooth transition effect */
  }
  

   .radio-box:hover .icon {
    color: #00a8a8; /* Change icon color on hover */
  }
  .four-box .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }

  
 input:checked + .icon {
    color: #00a8a8;
  }
  
p {
    margin: 0;
  }
  
 .input-section {
    transform: translateY(100%); /* Initially set to be below the viewport */
    transition: transform 0.5s ease-in-out; /* Add transition for smooth animation */
  }
  
.input-section.active {
    transform: translateY(0); /* Slide up the input section when active */
  }
  .input-section input {
    width: 100%; /* Set width to 100% to fill the container */
    padding: 10px; /* Add padding for better visibility */
    margin-bottom: 10px; /* Add margin for spacing */
    border: 1px solid #ccc; /* Add a border for clarity */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }