@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import "@fortawesome/fontawesome-free/css/all.css";
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #01BBBB;
  opacity: 1;
}

.swiper-pagination {
  margin: 10px
}
@media (max-width: 600px) {
	.swiper-pagination {
		top: 0px;
    
	  }
}
/* span.swiper-pagination-bullet1 {
  background-color: #000;
  opacity: 1;
} */
/* target all bullets */
span.swiper-pagination-bullet {
  background-color: rgb(255, 255, 255);
  opacity: 1;
}
.tawkto-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4b7bec;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}